import React, { useState } from 'react';
import { pdf, Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { ExportButton, Styles } from './UserDataToPdfStyles';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { IonToast } from '@ionic/react';


export default function UserDataToPdf({ cardData }) {
  const { user } = useAuth0();
  const { t } = useTranslation();
  const [isExporting, setIsExporting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const userCardData = cardData.filter((data) => data.userMail === user.email);

  function arrayBufferToBase64(buffer) {
    const bytes = new Uint8Array(buffer);
    return window.btoa(String.fromCharCode(...bytes));
  }

  async function exportToPDF() {
    setIsExporting(true);
  
    try {
      const itemsPerPage = 8;
      const itemsPerRow = 2;
  
      // Dokument und Seiten erstellen
      const docChunks = [];
      for (let i = 0; i < userCardData.length; i += itemsPerPage) {
        const chunk = userCardData.slice(i, i + itemsPerPage);
  
        const chunkRows = [];
        for (let j = 0; j < chunk.length; j += itemsPerRow) {
          chunkRows.push(chunk.slice(j, j + itemsPerRow));
        }
        docChunks.push(chunkRows);
      }
  
      const doc = (
        <Document>
          {docChunks.map((chunkRows, chunkIndex) => (
            <Page key={chunkIndex} size="A4" style={Styles.page}>
              <View style={Styles.header}>
                <Text style={Styles.title}>
                  {t('saveddatafrom')} {user.given_name || user.nickname}
                </Text>
                <Text style={Styles.subtitle}>
                  {t('createdon')}: {new Date().toLocaleString()}
                </Text>
              </View>
              <View style={Styles.cardContainer}>
                {chunkRows.map((row, rowIndex) => 
                  row.map((item, itemIndex) => (
                    <View key={`${rowIndex}-${itemIndex}`} style={Styles.card}>
                      <Text style={{ color: '#008080' }}>
                        {t('date')}: {item.date}
                      </Text>
                      <Text style={{ color: '#c92a2a' }}>
                        {t('bloodsugarlevel')}: {item.bloodsugar} mg/dl
                      </Text>
                      <Text style={{ color: '#e67700' }}>
                        {t('carbohydrates')}: {item.carbohydrates} g
                      </Text>
                      <Text style={{ color: '#5c940d' }}>
                        {t('usedinsulin')}: {item.insulin}
                      </Text>
                      <Text style={{ color: '#2b8a3e' }}>
                        {t('insulinfactor')}: {item.daytimeFactor}
                      </Text>
                      <Text style={{ color: '#2b8a3e' }}>
                        {t('correctionfactor')}: {item.correctionFactor}
                      </Text>
                      <Text style={{ color: '#364fc7' }}>
                        {t('injectedinsulin')} {t('quantity')}:{' '}
                        {item.calculateUnit}
                      </Text>
                    </View>
                  ))
                )}
              </View>
              <View style={Styles.footer}>
                <Text>InsulinApp.de</Text>
              </View>
              <Image style={Styles.image} src="/pageIcon.png" />
              <Text
                style={Styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>
          ))}
        </Document>
      );
  
      // PDF generieren und konvertieren
      const pdfBlob = await pdf(doc).toBlob();
      const pdfArrayBuffer = await pdfBlob.arrayBuffer();
      const base64Data = arrayBufferToBase64(pdfArrayBuffer);
  
      const fileName = `gespeicherte_daten_${user.given_name || user.nickname}_${new Date().toLocaleString()}.pdf`;
  
      if (Capacitor.isNativePlatform()) {
        try {
          // PDF als Base64 speichern
          await Filesystem.writeFile({
            path: fileName,
            data: base64Data,
            directory: Directory.Documents,
            recursive: true,
          });
          setToastMessage(t('pdfSaved'));
        } catch (error) {
          console.error('Fehler beim Speichern:', error);
          setToastMessage(`Fehler beim Speichern: ${error.message}`);
        }
      } else {
        // Browser-Download
        const url = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
        setToastMessage('PDF erfolgreich heruntergeladen!');
      }
    } catch (error) {
      console.error('PDF export error:', error);
      setToastMessage(`Fehler beim PDF-Export: ${error.message}`);
    } finally {
      setShowToast(true);
      setIsExporting(false);
    }
  }
  
  // Base64-Konvertierung
  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  

  return (
    <>
         <ExportButton 
      onClick={exportToPDF} 
      disabled={isExporting}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isExporting 
        ? t('exporting') 
        : isHovered 
          ? t('exportNow') 
          : t('export')}
    </ExportButton>
 
      
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={3000}
        position="bottom"
      />
    </>
  );
}