import { useAuth0 } from '@auth0/auth0-react';
import { NavbarHead, Navigation, Links } from './NavBarStyles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Navbar = () => {
  const { isAuthenticated } = useAuth0();
  const { t } = useTranslation();
  return (
    <>
      {isAuthenticated ? (
        <NavbarHead>
          <Navigation>
            <Links as={Link} to="/" passHref>
              {t('start')}
            </Links>
            <Links as={Link} to="/insulinFactor" passHref>
              {t('insulin')} <br />
              {t('factor')}
            </Links>
            <Links as={Link} to="/correctionFactor" passHref>
              {t('correction')} <br />
              {t('factor')}
            </Links>
            <Links as={Link} to="/savedInsulinData" passHref>
              {t('data')}
            </Links>
          </Navigation>
        </NavbarHead>
      ) : null}
    </>
  );
};
