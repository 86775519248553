import { useState, Fragment } from 'react';
import {
  EntryForm,
  LabelBz,
  BloodDrop,
  Blood,
  DataInput,
  LabelCa,
  Carbohydrates,
  Donut,
  LabelIu,
  InsulinSelect,
  InsulinOption,
  LabelFa,
  FactorSelect,
  FactorOption,
  Button,
  InsulinUnits,
  FullInject,
  EmptyInject,
  SuccessMessage,
} from './HomeInputStyles';
import leereSpritze from '../../public/leereSpritze.png';
import volleSpritze from '../../public/volleSpritze.png';
import blood from '../../public/blood.PNG';
import blooddrop from '../../public/blooddrop.PNG';
import carbohydratesImg from '../../public/carbohydratesImg.png';
import donut from '../../public/donut.png';
import handleInsulinUnit from '../../utils/calculate';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { UseCorrectionFactorById } from '../../pages/api/getCorrectionFactor';
import { UseInsulinFactorById } from '../../pages/api/getInsulinFactor';
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';
import Loading from '../PageLoader/PageLoader';



export default function Input() {
  const { user, isLoading } = useAuth0();
  const { t } = useTranslation();
  const [bloodsugar, setBloodsugar] = useState('');
  const [carbohydrates, setCarbohydrates] = useState('');
  const [insulin, setInsulin] = useState('');
  const [value, setValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState(null);
  const { correctionFactors } = UseCorrectionFactorById();
  const { insulinFactor } = UseInsulinFactorById();

  if (isLoading || !user) return null;
  if (isLoading || !correctionFactors || !insulinFactor ) return <Loading />;

  function handleUserDayFactor(insulinFactor) {
    const currentHour = new Date().getHours();
    if (currentHour >= 6 && currentHour < 11) return insulinFactor?.morningfactor || 1;
    if (currentHour >= 11 && currentHour < 17) return insulinFactor?.lunchfactor || 1;
    if (currentHour >= 17 && currentHour < 22) return insulinFactor?.eveningfactor || 1;
    return insulinFactor?.latefactor || 1;
  }

  function handleUserCorrectionFactor(correctionFactors) {
    const currentHour = new Date().getHours();
    if (currentHour >= 6 && currentHour < 11) return correctionFactors?.morningcorrectionfactor || 1;
    if (currentHour >= 11 && currentHour < 17) return correctionFactors?.lunchcorrectionfactor || 1;
    if (currentHour >= 17 && currentHour < 22) return correctionFactors?.eveningcorrectionfactor || 1;
    return correctionFactors?.latecorrectionfactor || 1;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const daytimeFactor = handleUserDayFactor(insulinFactor);
    const correctionFactor = handleUserCorrectionFactor(correctionFactors);
    const calculateUnits = handleInsulinUnit(bloodsugar, carbohydrates, daytimeFactor, correctionFactor);

    setFormData({ bloodsugar, carbohydrates, insulin, daytimeFactor, correctionFactor, calculateUnits });
    setValue(calculateUnits);
    setShowPopup(true);
  }

  async function confirmSave() {
    const cardData = {
      bloodsugar: formData.bloodsugar,
      carbohydrates: formData.carbohydrates,
      insulin: formData.insulin,
      daytimeFactor: formData.daytimeFactor,
      correctionFactor: formData.correctionFactor,
      calculateUnit: formData.calculateUnits,
      userMail: user.email,
      date: new Date().toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }),
    };

    const response = await fetch('https://insulinapp-api.vercel.app/api/insulindata', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(cardData),
    });

    if (response.ok) {
      // Reset the form values and show success message
      setBloodsugar('');
      setCarbohydrates('');
      setInsulin('');
      setTimeout(() => setValue(''), 15000)
      setShowPopup(false);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    }
  }

  return (
    <>
      {showPopup && (
        <ConfirmationPopup
          data={formData}
          onConfirm={confirmSave}
          onCancel={() => setShowPopup(false)}
        />
      )}
      {showSuccess && <SuccessMessage className="success-message">{t('dataSaved')}</SuccessMessage>}

      <EntryForm onSubmit={handleSubmit}>
        <LabelBz htmlFor="bloodsugar">
          {t('bloodsugar')} <br /> mg/dl
          <BloodDrop src={blooddrop} alt="blooddrop" />
          <Blood src={blood} alt="blood" />
          <DataInput
            type="decimal"
            name="bloodsugar"
            placeholder={t('currentbloodglucosevalue')}
            id="bloodsugar"
            min={0}
            inputMode="numeric"
            maxLength={3}
            required
            value={bloodsugar}
            onChange={(e) => setBloodsugar(e.target.value)}
          />
        </LabelBz>
        <LabelCa htmlFor="carbohydrates">
          {t('carbohydrates')} (Khd) <br /> g (Gramm)
          <Carbohydrates src={carbohydratesImg} alt="carbohydrates" />
          <Donut src={donut} alt="donut" />
          <DataInput
            type="decimal"
            name="carbohydrates"
            placeholder={t('howmanyKhd65g')}
            id="carbohydrates"
            maxLength={3}
            min={0}
            inputMode="numeric"
            required
            value={carbohydrates}
            onChange={(e) => setCarbohydrates(e.target.value)}
          />
        </LabelCa>
        <Fragment>
          <LabelIu htmlFor="insulin">
            {t('whichinsulin1')} <br /> {t('whichinsulin2')}
            <InsulinSelect
              name="setinsulinSelect"
              id="setinsulinSelect"
              value={insulin}
              onChange={(e) => setInsulin(e.target.value)}
            >
              <InsulinOption value="">{t('chooseinsulin')}</InsulinOption>
              {[
                { value: 'Fiasp', label: 'Fiasp' },
                { value: 'Hum Normal', label: 'Hum Normal' },
              ].map((option) => (
                <InsulinOption key={option.value} value={option.value}>
                  {option.label}
                </InsulinOption>
              ))}
            </InsulinSelect>
          </LabelIu>
        </Fragment>
        <Button type="submit">{t('confirm')}</Button>
        <InsulinUnits>
          {value} / {t('injectunits')} <br /> {t('ofinsulin')}
          <FullInject src={volleSpritze} alt="Full syringe" />
          <EmptyInject src={leereSpritze} alt="Empty syringe" />
        </InsulinUnits>
        <LabelFa htmlFor="factor">
          {t('daytimefactor')}
          <FactorSelect name="dayfactorSelect" id="dayfactorSelect" value={handleUserDayFactor(insulinFactor)}>
            <FactorOption value={handleUserDayFactor(insulinFactor)}>
              {t('insulinfactor')} {handleUserDayFactor(insulinFactor)}
            </FactorOption>
          </FactorSelect>
          <FactorSelect
            name="correctionfactorSelect"
            id="correctionfactorSelect"
            value={handleUserCorrectionFactor(correctionFactors)}
          >
            <FactorOption value={handleUserCorrectionFactor(correctionFactors)}>
              {t('correction')} {handleUserCorrectionFactor(correctionFactors)}
            </FactorOption>
          </FactorSelect>
        </LabelFa>
      </EntryForm>
    </>
  );
}
