import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, ModalContent, ModalWrapper, StyledButton, Text, Title } from '../PopupModal/PopuipModalStyles';

const ConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  titleKey,
  descriptionKey
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;


  return (
    <ModalWrapper>
    <ModalContent>
    <Title>{t(titleKey)}</Title>
      <Text>{t(descriptionKey)}</Text>
      <ButtonWrapper>
        <StyledButton onClick={onConfirm} primary>
          {t('yesDelete')}
        </StyledButton>
        <StyledButton onClick={onClose}>{t('cancel')}</StyledButton>
      </ButtonWrapper>
    </ModalContent>
  </ModalWrapper>
  );
};

export default ConfirmationDialog;
