import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { FiSearch } from "react-icons/fi"; // Lupen-Icon
import { RiDeleteBinLine, RiLineHeight } from 'react-icons/ri';
import {
  WrapperSaved,
  SearchData,
  DataInput,
  CardGrid,
  Details,
  Summary,
  Timestampwraper,
  IconWrapper,
  Saved,
  BloodSugar,
  Carbohydrates,
  Insulin,
  Factor,
  InsulinUnits,
  SavedData,
  Button,
  CheckBox,
  DeleteIcon,
  ButtonWrapper,
  SearchIconWrapper,
  CheckBoxInput
} from "./SavedDataStyles";
import UserDataToPdf from "../UserDataToPdf/UserDataToPdf";
import Loading from "../PageLoader/PageLoader";
import LoginButton from "../Login/Login";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";


export default function SavedDataInjected() {
  const { user } = useAuth0();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedCards, setSelectedCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const searchRef = useRef(null);

  useEffect(() => {
    if (user) {
      setLoading(true);
      fetch(`https://insulinapp-api.vercel.app/api/insulindata?userMail=${user.email}`)
        .then((response) => response.json())
        .then((insulinData) => {
          setData(insulinData);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [user]);

  // Event-Listener: Schließt das Suchfeld bei Klick außerhalb
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!user) {
    return (
      <div>
        Du bist nicht eingeloggt <br />
        <LoginButton />
      </div>
    );
  }

  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);
  const handleMouseEnter = () => setIsSearchOpen(true); 
  const handleMouseLeave = () => setIsSearchOpen(false); 


  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const parseDateTimeString = (dateTimeString) => {
    const [datePart, timePart] = dateTimeString.split(" ");
    const [day, month, year] = datePart.split(".").map((num) => parseInt(num, 10));
    const [hours, minutes, seconds] = timePart
      ? timePart.split(":").map((num) => parseInt(num, 10))
      : [0, 0, 0];
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const filteredData = data
    .filter((item) => item.date.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => parseDateTimeString(b.date) - parseDateTimeString(a.date));

  const handleSelectCard = (id) => {
    setSelectedCards((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const removeSelectedCards = () => {
    setItemToDelete(null);
    setIsDialogOpen(true);
  };

  const confirmRemoveSelectedCards = async () => {
    const successfulDeletes = [];
    for (const id of selectedCards) {
      const result = await removeCard(id);
      if (result) {
        successfulDeletes.push(id);
      }
    }
    setData((prevData) => prevData.filter((item) => !successfulDeletes.includes(item._id)));
    setSelectedCards([]);
    setIsDialogOpen(false);
  };

  const removeCard = async (id) => {
    try {
      const response = await fetch(`https://insulinapp-api.vercel.app/api/insulindata/${id}`, {
        method: "DELETE",
      });

      if (response.ok) {
        setData((prevData) => prevData.filter((item) => item._id !== id));
        return true;
      } else {
        const errorData = await response.json();
        console.error("Error deleting data:", errorData);
        return false;
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      return false;
    }
  };

  const handleRemoveCard = (id) => {
    setItemToDelete(id);
    setIsDialogOpen(true);
  };

  const confirmRemoveCard = async () => {
    if (itemToDelete) {
      await removeCard(itemToDelete);
      setItemToDelete(null);
    }
    setIsDialogOpen(false);
  };

  if (loading) return <Loading />;

  return (
    <WrapperSaved>
      <SavedData>{t("storedvalues")}</SavedData>
      <ButtonWrapper>
      <SearchData
        ref={searchRef}
        onMouseEnter={handleMouseEnter} 
        onMouseLeave={handleMouseLeave} 
      >
        <SearchIconWrapper onClick={toggleSearch}>
          <FiSearch />
        </SearchIconWrapper>
        <DataInput
          type="text"
          placeholder={t("search")}
          value={search}
          onChange={handleChange}
          style={{
            width: isSearchOpen ? "180px" : "0",
            opacity: isSearchOpen ? "1" : "0",
            padding: isSearchOpen ? "0.5rem" : "0",
            border: isSearchOpen ? "1px solid beige" : "none",
            transition: "all 0.3s ease",
          }}
        />
      </SearchData>

      <UserDataToPdf cardData={data} />
      </ButtonWrapper>
      <Button onClick={removeSelectedCards}>{t("deleteSelected")}</Button>

      <CardGrid>
        {filteredData.map((item) => (
          <Details key={item._id}>
            <Summary>
              <CheckBox>
                <CheckBoxInput
                  type="checkbox"
                  checked={selectedCards.includes(item._id)}
                  onChange={() => handleSelectCard(item._id)}
                />
              </CheckBox>
              <Timestampwraper>{item.date}</Timestampwraper>
              <IconWrapper>
              <RiLineHeight />
              </IconWrapper>
            </Summary>
            <Saved>
              <DeleteIcon>
              <RiDeleteBinLine onClick={() => handleRemoveCard(item._id)} />
              </DeleteIcon>
              <BloodSugar>
                {t("bloodsugarlevel")}: <br /> {item.bloodsugar} mg/dl
              </BloodSugar>
              <Carbohydrates>
                {t("carbohydrates")}: <br /> {item.carbohydrates} g
              </Carbohydrates>
              <Insulin>
                {t("usedinsulin")}: <br /> {item.insulin}
              </Insulin>
              <br />
               <Factor>
                 {t('insulinfactor')}: <br /> {item.daytimeFactor}
               </Factor>
               <Factor>
                 {t('correctionfactor')}: <br />
                 {item.correctionFactor}
               </Factor>
               <InsulinUnits>
                 {t('injectedinsulin')}
                 <br /> {t('quantity')}: {item.calculateUnit}
               </InsulinUnits>
            </Saved>
          </Details>
        ))}
      </CardGrid>

      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={itemToDelete ? confirmRemoveCard : confirmRemoveSelectedCards}
        titleKey={itemToDelete ? "deleteEntryTitle" : "deleteSelectedEntriesTitle"}
        descriptionKey={itemToDelete ? "deleteEntryDescription" : "deleteSelectedEntriesDescription"}
      />
    </WrapperSaved>
  );
}
