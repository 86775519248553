import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';
import { useState } from 'react';
import styled from 'styled-components';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const [loading, setLoading] = useState(false);

  const login = async () => {
    setLoading(true);
    try {
      await loginWithRedirect({
        async openUrl(url) {
          await Browser.open({
            url,
            windowName: '_self',
          });
        },
      });
    } catch (error) {
      console.error('Login failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledButton onClick={login} disabled={loading}>
      {loading ? 'Logging in...' : 'Log in'}
    </StyledButton>
  );
};

export default LoginButton;

const StyledButton = styled.button`
  all: unset;
  padding: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 90%;
  height: 3rem;
  background-color: gray;
  filter: drop-shadow(0 0 0.1em var(--drop-shadow));
  border-radius: 0.5rem;
  color: #fff;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  cursor: pointer;
`;
