// import styled, { createGlobalStyle } from 'styled-components';



// export const GlobalStyle = createGlobalStyle`
// *,*::after, *::before {
//     box-sizing:border-box;
// }
// body {
//     margin: 0;
//     padding: 0;
//     background-image: url(/happyBlooddrop.png);
//     background-size: cover;   /* Bild skaliert, um den gesamten Bildschirm zu füllen */
//   background-position: center center; /* Bild wird zentriert */
//   background-repeat: no-repeat; 
//   overflow: auto;
//     @media only screen and (min-device-width: 480px){
//     gap: 4rem;
//     .scroll-container {
//     max-height: 90vh;
//   }
    
// }
// @media (max-width: 600px) {
//     .scroll-container {
//     max-height: 80vh; /* Weniger Platz auf kleineren Bildschirmen */
//   }
//     .grid {
//        width: 100%;
//        flex-direction: column;
//     }}}
// `;

// export const Popup = styled.div`
// position: sticky;
// background-color: green;
// text-align: center;
// color: white;
// padding: 10px;
// border-radius: 5px;
// z-Index: 1000;
// `;


import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *,*::after, *::before {
    box-sizing: border-box;
  }
  
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  body {
    background-image: url(/happyBlooddrop.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden; // Verhindert Scrollen des Body
  }
  
  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .scroll-container {
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; // Für besseres Scrolling auf iOS
  }
  
  @media only screen and (min-width: 480px) {
    body {
      gap: 4rem;
    }
  }
  
  @media (max-width: 600px) {
    .grid {
      width: 100%;
      flex-direction: column;
    }
  }
`;

export const Popup = styled.div`
  position: sticky;
  top: 0;
  background-color: green;
  text-align: center;
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
`;

